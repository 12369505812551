<template>
  <div class="camera">
    <div class="wrapper">
      <!--<button @click="this.$parent.showCamera = false" class="button-close">x</button>-->
      <!--<button class="button-snap p-button p-component p-button-sm btn-color " @click="toggleCamera()">
        <span v-if="!isCameraOpen">Open Camera</span>
        <span v-else>Close Camera</span>
      </button>-->

      <div class="video-container" style="margin-bottom: 15px;">
        <div v-if="isCameraOpen == false">
          Sua Câmera pode estar apresentando algum problema ou estar desligada ou o seu navegador nao suporta esse recurso.
          caso seja seu navegador utilize o navegador Chrome da Google.
        </div>

        <div class="row">
          <div class="col-md-12" v-if="!ativar_camera">
            <pm-Button
              type="button"
              @click="AtivarCamera()"
              icon="pi pi-camera"
              label="Ativar câmera"
              title="Ativar câmera"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px"
            >
            </pm-Button>
          </div>
          <div class="col-md-6" style="text-align: center;" v-if="ativar_camera">
            <video v-show="isCameraOpen" class="camera-video" ref="camera" :width="450" :height="687" autoplay playsinline style="width: 100%;" ></video>

            <button v-if="!isPhotoTaken && isCameraOpen" class="p-button p-component p-button-sm btn-color" @click="takePhoto">
              <span>Capturar Foto</span>
            </button>
          </div>

          <div class="col-md-6">
            <canvas id="photoTaken" v-show="isPhotoTaken" class="canvas-photo" ref="canvas" :width="800" :height="687" style="width:80%"></canvas>
            <div style="text-align: center;margin-bottom: 10px;">
              <button v-show="isPhotoTaken && isCameraOpen" class="button-snap p-button p-component p-button-sm btn-color" @click="downloadImage" style="margin-right:5px">
                Salvar e Enviar

              </button>
              <button v-show="isPhotoTaken && isCameraOpen" class="button-snap p-button p-button-danger p-component p-button-sm btn-color" @click="cancelarImage">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>


      <!--<pm-Dialog
        v-model:visible="modal"
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '100vw' }"
        header="Preview Foto"
      >
        <div class="col-md-6">
          <canvas id="photoTaken" v-show="isPhotoTaken" class="canvas-photo" ref="canvas" :width="800" :height="687"></canvas>
        </div>

        <div class="col-md-12" style="text-align: center;">
          <button v-show="isPhotoTaken && isCameraOpen" class="button-snap p-button p-component p-button-sm btn-color" @click="downloadImage" style="margin-right:5px">
            Salvar e Enviar

          </button>
          <button v-show="isPhotoTaken && isCameraOpen" class="button-snap p-button p-button-danger p-component p-button-sm btn-color" @click="cancelarImage">
            Cancelar
          </button>
        </div>
      </pm-Dialog>-->
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'Camera',
  props: {
    id: {},
  },
  data() {
    return {
      isCameraOpen: true,
      fechar_camera: true,
      isPhotoTaken: false,
      modal: false,
      ativar_camera: false,
    }
  },
  beforeMount(){
    //this.createCameraElement();
  },
  methods: {
    AtivarCamera(){
      this.ativar_camera = true
      this.createCameraElement();
    },
    createCameraElement () {
      const constraints = (window.constraints = {
        audio: false,
        video: true
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream
        })
        .catch(error => {
          //this.isCameraOpen = false
          //alert(error, "May the browser didn't support or there is some errors.")
        })
    },
    stopCameraStream () {
      const tracks = this.$refs.camera.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
      console.log('CameraClosed')
      this.ativar_camera = false
    },
    toggleCamera () {
      if (this.isCameraOpen) {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.stopCameraStream()
        console.log('closed');
      } else {
        this.isCameraOpen = true
        this.createCameraElement()
        console.log('open');
      }
    },
    takePhoto () {
      this.isPhotoTaken = !this.isPhotoTaken
      this.fechar_camera = false


      const context = this.$refs.canvas.getContext('2d')
      const photoFromVideo = this.$refs.camera

      //context.drawImage(photoFromVideo, 0, 0, 450, 337)
      context.drawImage(photoFromVideo, 0, 0, 800, 687)
    },
    async downloadImage() {
      const download = document.getElementById("downloadPhoto");

      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")

      this.cancelarImage();

      let data;
      try {
        fetch(canvas)
        .then(res => res.blob())
        .then(blob => {

          let formData = new FormData();
          formData.append('foto', blob, 'filename.png');
          formData.append('tipo', 1);

          let config = {
                header : {
                'Content-Type' : 'multipart/form-data'
              }
          }
          data = axios.post('fotos/perfil/'+this.id, formData, config);
        });

        this.$vaToast.init({
          message: "Foto salva com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        });

        this.$emit('perfil');
        this.stopCameraStream()
      }
      catch(e) {
        // console.log(e);
        this.$vaToast.init({
          message: "Erro ao cadastrar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }

    },
    cancelarImage(){
      this.$emit('perfil');
      this.fechar_camera = true
      this.isPhotoTaken = false
      this.ativar_camera = false
      this.stopCameraStream()
    }
  }
}
</script>

<style>
.camera {
  /*position: fixed; */
  top: 0;
  left: 0;
  /* width: 100vw; */
  height: 100vh;
  /* overflow: hidden; */
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  /*position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;*/
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  height: 98%;
  background-color: white;
  border: solid 2px rgb(177, 177, 177);
}

button {
  border: solid 1px rgb(167, 167, 167);
  font-size: 25px;
  cursor: pointer;
}

.button-close {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 25px;
  height: 30px;
}

.button {
  width: 140px;
  height: 40px;
}

.video-container {
  display: flex;
  flex-direction: column;
}

@media(max-width:1500px){
  .camera-video{
    height:400px
  }
}

@media(max-width:1180px){
  .camera-video {
      height: 240px;
  }
}
</style>
